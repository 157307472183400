import React, { useMemo } from 'react'
import * as classes from './Partners.module.scss'
import Container from '../../../ui/Container/Container'
import { deleteHtmlTag } from '../../../../utils/deleteHtmlTag'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'

function Partners({ data, lang }) {
    const sectionTitle = useMemo(() => deleteHtmlTag(data?.partnersSectionTitle?.value, 'p'), [])

    if (!data?.partnersItems?.nodes?.length) {
        return null
    }

    return (
        <section className={classes.Section}>
            <Container>
                {sectionTitle ? <SectionTitle className={classes.SectionTitle} title={sectionTitle} lang={lang} /> : null}

                {data?.partnersItems?.nodes?.length > 0 && (
                    <div className={classes.Items}>
                        {data.partnersItems.nodes.map((item, index) => (
                            <div className={classes.Item} key={index}>
                                {item?.photo?.url && (
                                    <div
                                        className={classes.ItemImage}
                                        style={{
                                            backgroundImage: `url(${item.photo.url})`
                                        }}
                                    />
                                )}

                                <div className={classes.ItemContent}>
                                    {item?.name && <div className={classes.ItemName}>{item.name}</div>}
                                    {item.text && <div className={classes.ItemText} dangerouslySetInnerHTML={{ __html: item.text }} />}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </Container>
        </section>
    )
}

export default Partners
